import './App.css';
import {
  Link
} from "react-router-dom";

function App() {
  


  return (
    <div className="App">
      <div className="text">
        <h1>The Mandalorian</h1>
        <p>Jeg heter Din Djarin, men du kan kalle meg Mando</p>
        <p class="tekst2">Mine opplevelser som en bounty hunter:</p>
      </div>
      <img className="mando" src="https://m.media-amazon.com/images/M/MV5BZDhlMzY0ZGItZTcyNS00ZTAxLWIyMmYtZGQ2ODg5OWZiYmJkXkEyXkFqcGdeQXVyODkzNTgxMDg@._V1_.jpg" alt=""/>
      <Link to="/grogu">
      <div className="Grogu" href="/grogu">
        <div id="play" >PLAY</div>
        <img className="regular" src="/img/Grogu CG.png"/>
        <img className="blur" src="/img/Grogu CG Blur.png"/>
      </div>
      </Link>
    </div>
  );
}

export default App;
