import './game.css';
import useMousePosition from './MousePosition';
import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

function Game() {
  
  const { x: mouseX, y :mouseY } = useMousePosition();
  const leftRef = useRef(null);
  const rightRef = useRef(null);

  const rightOffset = Math.PI/8
  const leftOffset = 0

  var leftX = 0;
  var leftY = 0;
  var rightX = 0;
  var rightY = 0;

  var leftWidth = 0;
  var leftHeight = 0;
  var rightWidth = 0;
  var rightHeight = 0;


  if(leftRef?.current && rightRef?.current){
    leftX = leftRef.current.offsetLeft;
    leftY = leftRef.current.offsetTop;
    rightX = rightRef.current.offsetLeft;
    rightY = rightRef.current.offsetTop;
    leftWidth = leftRef.current.width;
    rightWidth = rightRef.current.width;
    leftHeight = leftRef.current.height;
    rightHeight = rightRef.current.width;
  }

  const getLeftAngle = () =>{
    const centerx = leftX+(leftWidth/2)
    const centery = leftY+(leftHeight/2)
    const difX = mouseX-centerx
    const difY = mouseY-centery 
    const tanVinkel = difX/difY
    var vinkel = -Math.atan(tanVinkel) - leftOffset
    if (difY<0){
      vinkel-=Math.PI
    }
    return vinkel
  }

  

  const getRightAngle = () =>{
    const centerx = rightX+(rightWidth/2)
    const centery = rightY+(rightHeight/2)
    const difX = mouseX-centerx
    const difY = mouseY-centery 
    const tanVinkel = difX/difY
    var vinkel = -Math.atan(tanVinkel) - rightOffset
    if (difY<0){
      vinkel-=Math.PI
    }
    return vinkel
  }

  


  return (
    <div className="game">
      <div>
        <img id="rock" src="/img/mossy rock ground.png"/>
      </div>
      <div id="knob">
        <img id="ball" src="/img/razorcrest knob 2.png"style={{top: mouseY, left: mouseX}}/> 
      </div>
      <div id="grogu">
        <img id="head" src="/img/grogu head 2.png"/>
        <img id="left" ref={leftRef} src="/img/grogu left arm 2.png"style={{transform: "rotate(" + getLeftAngle() +"rad)"}}/>
        <img id="right" ref={rightRef} src="/img/grogu right arm 2.png" style={{transform: "rotate(" + getRightAngle() +"rad)"}}/>
        <img id="torso" src="/img/grogu torso.png"/>
      </div>
      <Link to="/">
        <img id="goback" src="/mando-helmet.png"/>
      </Link>
    </div>
  );
}

export default Game;
